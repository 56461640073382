<template>
  <div>
    <h1 class="pb-3">Standard Compliance Checks</h1>
    <div v-for="(standard, index) in standardChecks" :key="'standard-' + index">
      <h3 class="pt-2 mb-2">{{ standard.label }}</h3>
      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === FTF"
        class="mb-3"
      />
      <ValidationObserver ref="faceToFaceValidation">
        <FaceToFaceInterview
          :data="getComplianceChecks(standard.label)"
          @onChange="
            compliance => handleComplianceChange(compliance, standard.label)
          "
          :showValidationErrors="showValidationErrors"
          label="Interview conducted"
          v-if="standard.label === FTF && isVerified(standard.label)"
        />
      </ValidationObserver>
      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === ID"
        class="mb-3"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === POA"
        class="mb-3"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === NIN"
        class="mb-3"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === EH"
        class="mb-3"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === DL"
        class="mb-3"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === REFERENCES"
        class="mb-3"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === RTW"
        class="mb-3"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === ECS"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === AHHA"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === FSQ"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === IC"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === IB"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === AT"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === IT"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === HST"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === FST"
      />

      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === LT"
      />
      <Checkbox
        @change="value => updateChecks(value, standard.label)"
        :selected-values="
          isVerified(standard.label) ? [standard.options[0].value] : []
        "
        :options="standard.options"
        isLarge
        v-if="standard.label === FHA"
      />

      <DLExpiryDate
        ref="DLExpiryDate"
        :data="getComplianceChecks(standard.label)"
        @onChange="
          compliance => handleComplianceChange(compliance, standard.label)
        "
        :showValidationErrors="showValidationErrors"
        label="Expiry Date"
        v-if="standard.label === DL && isVerified(standard.label)"
      />

      <div
        class="mb-4"
        v-if="standard.label === REFERENCES && isVerified(standard.label)"
      >
        <ValidationObserver ref="employmentValidation">
          <References
            :showValidationErrors="showValidationErrors"
            :references="getReferences"
            @onChange="handleReferencesChange"
          />
        </ValidationObserver>
        <v-text-field
          @change="checkReferenceValue"
          :value="currentWorker.yearsReferenced"
          type="number"
          placeholder="Number of years"
          suffix="year(s)"
          class="pt-6"
          label="Years references taken cover (with no gaps of more than 6 months)"
        />
      </div>
      <div v-if="isVerified(standard.label) && standard.label === RTW">
        <ValidationObserver ref="rightToWorkValidation">
          <RightToWorkDetails
            @onChange="compliance => handleComplianceChange(compliance, RTW)"
            :showValidationErrors="showValidationErrors"
            :data="getComplianceChecks(RTW)"
          />
        </ValidationObserver>
      </div>
      <div class="d-flex flex-column" v-if="standard.label === BPSS">
        <Checkbox
          class="mb-2"
          @change="value => updateChecks(value, standard.label)"
          :selected-values="
            isVerified(standard.label) ? [standard.options[0].value] : []
          "
          :options="standard.options"
          isLarge
        />
        <BPSSCompletionDate
          ref="bpssCompletionDate"
          :data="getComplianceChecks(standard.label)"
          @onChange="
            compliance => handleComplianceChange(compliance, standard.label)
          "
          label="Completion Date"
          v-if="isVerified(standard.label)"
        />
        <BPSSExpiryDate
          ref="bpssExpiryDate"
          :data="getComplianceChecks(standard.label)"
          @onChange="
            compliance => handleComplianceChange(compliance, standard.label)
          "
          label="Expiry Date"
          v-if="isVerified(standard.label)"
        />
      </div>
      <ComplianceFiles
        ref="complianceFiles"
        :compliance="getComplianceChecks(standard.label)"
        v-if="standard.label !== ECS && isVerified(standard.label)"
        :required="[BPSS].includes(standard.label)"
        :showValidationErrors="
          [BPSS].includes(standard.label) ? showValidationErrors : false
        "
      />
      <v-divider class="mb-2 mt-2" />
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox";
import RightToWorkDetails from "@/components/workers/common/RightToWorkDetails";
import { map, filter, first, pick } from "lodash";
import { ValidationObserver } from "vee-validate";
import { findComplianceChecks } from "@/utils/workers";
import References from "@/components/workers/compliance/components/References";
import FaceToFaceInterview from "@/components/workers/compliance/components/ConductedDate";
import {
  COMPLIANCE_NAMES,
  INITIAL_REFERENCE,
  STANDARD_COMPLIANCE_CHECKS,
  STANDARD_COMPLIANCE_CHECKS_CSP
} from "@/constants/workers";
import ComplianceFiles from "@/components/workers/compliance/components/ComplianceFiles";
import DLExpiryDate from "@/components/workers/compliance/components/ExpiryDate.vue";
import BPSSCompletionDate from "@/components/workers/compliance/components/ConductedDate.vue";
import BPSSExpiryDate from "@/components/workers/compliance/components/ExpiryDate.vue";
import { createNamespacedHelpers } from "vuex";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { FETCH_COMPLIANCE_CHECK_TYPES } from "@/store/modules/data/actions";
import { GET_COMPLIANCE_CHECK_TYPES } from "@/store/modules/data/getters";

const {
  REFERENCES,
  EH,
  RTW,
  FTF,
  NIN,
  OCB,
  POA,
  ID,
  DL,
  ECS,
  AHHA,
  FHA,
  FSQ,
  IC,
  IB,
  AT,
  IT,
  HST,
  FST,
  LT,
  BPSS
} = COMPLIANCE_NAMES;

const { mapActions, mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  props: {
    /**
     *  Data object
     */
    currentWorker: {
      type: Object,
      required: true
    },
    showValidationErrors: Boolean,
    permissions: Object,
    isUpdate: Boolean
  },
  components: {
    BPSSCompletionDate,
    BPSSExpiryDate,
    Checkbox,
    References,
    RightToWorkDetails,
    ValidationObserver,
    FaceToFaceInterview,
    ComplianceFiles,
    DLExpiryDate
  },
  data() {
    return {};
  },
  created() {
    if (!this.complianceCheckTypes || !this.complianceCheckTypes.length) {
      this.fetchComplianceCheckTypes();
    }
  },
  computed: {
    ...mapGetters({
      complianceCheckTypes: GET_COMPLIANCE_CHECK_TYPES
    }),
    standardChecks() {
      const checks =
        process.env.VUE_APP_HIDE_COMPLIANCE_FOR_CSP !== "true"
          ? STANDARD_COMPLIANCE_CHECKS
          : STANDARD_COMPLIANCE_CHECKS_CSP;

      return filter(checks, ({ label }) =>
        this.complianceCheckTypes.includes(label)
      );
    },
    defaultReferences() {
      return [
        pick(INITIAL_REFERENCE, [
          "id",
          "referenceTypeName",
          "periodStartDate",
          "periodEndDate",
          "referenceReceivedDate",
          "isNew"
        ])
      ];
    },
    getReferences() {
      if (this.currentWorker.references.length > 0) {
        return this.currentWorker.references;
      }

      return this.defaultReferences;
    },
    FTF() {
      return FTF;
    },
    REFERENCES() {
      return REFERENCES;
    },
    RTW() {
      return RTW;
    },
    NIN() {
      return NIN;
    },
    OCB() {
      return OCB;
    },
    POA() {
      return POA;
    },
    ID() {
      return ID;
    },
    EH() {
      return EH;
    },
    DL() {
      return DL;
    },
    ECS() {
      return ECS;
    },
    AHHA() {
      return AHHA;
    },
    FHA() {
      return FHA;
    },
    FSQ() {
      return FSQ;
    },
    IC() {
      return IC;
    },
    IB() {
      return IB;
    },
    AT() {
      return AT;
    },
    IT() {
      return IT;
    },
    HST() {
      return HST;
    },
    FST() {
      return FST;
    },
    LT() {
      return LT;
    },
    BPSS() {
      return BPSS;
    }
  },
  methods: {
    ...mapActions({
      fetchComplianceCheckTypes: FETCH_COMPLIANCE_CHECK_TYPES
    }),
    handleChange() {
      this.$emit("onChange", this.currentWorker);
    },
    updateChecks(value, name) {
      const checked = value.length > 0;
      this.currentWorker.complianceChecks = map(
        this.currentWorker.complianceChecks,
        compliance => {
          if (compliance.complianceCheckName === name) {
            compliance.checkVerified = checked;
          }

          return compliance;
        }
      );
      if (!checked) {
        this.resetValidations();
      }
      this.$forceUpdate();
      this.handleChange();
    },
    checkReferenceValue(value) {
      if (value < 0) {
        return (this.currentWorker.yearsReferenced = 0);
      }

      this.currentWorker.yearsReferenced = value;
    },
    isVerified(name) {
      const complianceCheck = findComplianceChecks(this.currentWorker, name);
      return complianceCheck.checkVerified;
    },
    getComplianceChecks(name) {
      return findComplianceChecks(this.currentWorker, name);
    },
    handleComplianceChange(newCompliance, name) {
      this.currentWorker.complianceChecks = map(
        this.currentWorker.complianceChecks,
        compliance => {
          if (compliance.complianceCheckName === name) {
            compliance = newCompliance;
          }
          return compliance;
        }
      );
      this.handleChange();
    },
    handleReferencesChange(references) {
      this.currentWorker.newReferences = filter(references, reference => {
        if (reference.isNew) {
          return reference;
        }
      });

      map(references, reference => {
        if (reference.toBeDeleted && !reference.isNew) {
          if (!this.currentWorker.toBeDeletedReferences) {
            this.currentWorker.toBeDeletedReferences = [];
          }
          this.currentWorker.toBeDeletedReferences.push(reference);
        }
      });

      this.handleChange();
    },
    resetValidations() {
      if (first(this.$refs.employmentValidation)) {
        first(this.$refs.employmentValidation).reset();
      }
      if (first(this.$refs.faceToFaceValidation)) {
        first(this.$refs.faceToFaceValidation).reset();
      }
      if (first(this.$refs.nationalInsuranceNumber)) {
        first(this.$refs.nationalInsuranceNumber).$refs.validation.reset();
      }
      if (first(this.$refs.rightToWorkValidation)) {
        first(this.$refs.rightToWorkValidation).reset();
      }
      if (first(this.$refs.DLExpiryDate)) {
        first(this.$refs.DLExpiryDate).$refs.validation.reset();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
